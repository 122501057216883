(function ($) {
  var $html = $();
  var $body = $();
  var $template = $();
  var targetNode;

  Drupal.behaviors.sdSearchGnavFormatterV1 = {
    attached: false,
    attach: function (context) {
      if (this.attached) {
        return;
      }
      this.attached = true;
      $template = $('.js-sd-search-gnav-input-field', context);

      $html = $(context);
      $body = $(context);
      targetNode = $html[0].documentElement;

      var observer = new MutationObserver(function (mutations) {
        var length = mutations.length;

        for (var i = 0; i < length; i++) {
          var mutation = mutations[i];

          if (mutation.target.className.includes('search-overlay-displayed')) {
            $body.find('.js-search-active-screen-wrapper').each(function () {
              this.classList.add('transition-active');
            });
          }
        }
      });

      observer.observe(targetNode, {
        attributes: true,
        attributeFilter: ['class']
      });

      $template
        .off('click touchstart')
        .on('click touchstart', '.js-gnav-search-button', function () {
          $html.toggleClass('active-utility-overlay', true);
        });

      $template.on('click touchstart', '.js-search-close-icon-wrapper', function () {
        $html.toggleClass('active-utility-overlay', false);
      });
    }
  };
})(jQuery);
